import React from 'react'
import Helmet from 'react-helmet'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

import '../styles/templates/About.css'

export default function Template({ data }) {
  let {
    allPrismicAbout: {
      edges: [about],
    },
  } = data
  about = about.node
  return (
    <Layout>
      <SEO
        title="CONTANGO - About"
        keywords={['Contango Journal', 'Political Theory']}
      />
      <Helmet title="CONTANGO - About" />
      <div className="about">
        <h1>{about.data.about_header.text}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: about.data.about_description.html,
          }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrismicAbout {
    allPrismicAbout {
      edges {
        node {
          id
          data {
            about_header {
              text
              html
            }
            about_description {
              text
              html
            }
          }
        }
      }
    }
  }
`
